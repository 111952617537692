import React, { useEffect } from 'react'
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import i18n from 'i18next';
import Main from './Main';
import { useTheme } from '../config';
const RootStack = createStackNavigator();

const RootNavigation = () => {
  const { theme } = useTheme();
  const language = 'ja'
  
  /**
  * when reducer language change
  */
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  
  return (
    <NavigationContainer theme={theme}>
      <RootStack.Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
        <RootStack.Screen name="Main" component={Main} />
      </RootStack.Navigator>
    </NavigationContainer>
  )
}

export default RootNavigation