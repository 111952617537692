import React from 'react';
import {TouchableOpacity, StyleSheet} from 'react-native';
import {Image, Text} from '../components';
import {useTheme} from '../config';

export default function ServiceInfomation(props: any) {
  const {colors} = useTheme();
  const {style, onPress, day, title, image, description} = props;
  return (
    <TouchableOpacity
      style={[styles.contain, {backgroundColor: colors.card}, style]}
      onPress={onPress}
      activeOpacity={0.9}>
      <Text headline primaryColor semibold style={{marginBottom: 10}}>
        {day}
      </Text>
      <Image source={image} style={{width: 215, height: 100}} />
      <Text
        title3
        semibold
        style={{
          marginTop: 8,
        }}
        numberOfLines={1}>
        {title}
      </Text>
      <Text
        body2
        style={{
          marginTop: 8,
        }}
        numberOfLines={5}>
        {description}
      </Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  contain: {
    borderRadius: 8,
    paddingHorizontal: 20,
    paddingVertical: 10,
    width: 250,
  },
});
