export const SortOptions = [
  {
    value: 'low_price',
    icon: 'sort-amount-up',
    text: 'lowest_price',
  },
  {
    value: 'hight_price',
    icon: 'sort-amount-down',
    text: 'hightest_price',
  },
  {
    value: 'high_rate',
    icon: 'sort-amount-up',
    text: 'hightest_rating',
  },
  {
    value: 'popular',
    icon: 'sort-amount-down',
    text: 'popularity',
  }
]

export const SortSelected = {
  value: 'high_rate',
  icon: 'sort-amount-up',
  text: 'hightest_rating',
}