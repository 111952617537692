import { Images } from '../config';

export default [
  {
    id: '1',
    image: Images.topsitter.sitter1,
    name: 'Steve Garrett',
    major: 'Travel Agency',
    email: 'lewis.victor@milford.tv',
    address: 'Singapore, Golden Mile',
    point: '9.5',
    about:
      'Andaz Tokyo Toranomon Hills is one of the newest luxury hotels in Tokyo. Located in one of the uprising areas of Tokyo',
    performance: [
      {value: '97.01%', title: 'feedback'},
      {value: '10', title: 'service'},
      {value: '90', title: 'reviews'},
    ],
  },
  {
    id: '2',
    image: Images.topsitter.sitter3,
    name: 'Athena alizabeth',
    major: 'Software Engineer',
    email: 'lewis.victor@milford.tv',
    address: '667 Wiegand Gardens Suite 330',
    point: '9.5',
    about:
      'Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Curabitur aliquet quam id dui posuere blandit.',
    performance: [
      {value: '97.01%', title: 'feedback'},
      {value: '10', title: 'service'},
      {value: '90', title: 'reviews'},
    ],
  },
  {
    id: '3',
    image: Images.topsitter.sitter4,
    name: 'Paul',
    major: 'Software Engineer',
    email: 'lewis.victor@milford.tv',
    address: '667 Wiegand Gardens Suite 330',
    point: '9.5',
    about:
      'Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Curabitur aliquet quam id dui posuere blandit.',
    performance: [
      {value: '97.01%', title: 'feedback'},
      {value: '10', title: 'service'},
      {value: '90', title: 'reviews'},
    ],
  },
  {
    id: '4',
    image: Images.topsitter.sitter3,
    name: 'Dung',
    major: 'Software Engineer',
    email: 'lewis.victor@milford.tv',
    address: '667 Wiegand Gardens Suite 330',
    point: '9.5',
    about:
      'Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Curabitur aliquet quam id dui posuere blandit.',
    performance: [
      {value: '97.01%', title: 'feedback'},
      {value: '10', title: 'service'},
      {value: '90', title: 'reviews'},
    ],
  },
]