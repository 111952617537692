import { Images } from '../config';

export default [
  {
    id: '1',
    source: Images.topsitter.sitter1,
    name: '幸々実',
    rate: 5,
    date: 'May 2022',
    title: 'とても良いベビーシッター',
    comment:
      '実はとても良いです',
  },
  {
    id: '2',
    source: Images.topsitter.sitter2,
    name: '紗莉奈',
    rate: 5,
    date: 'May 2022',
    title: 'ベビーシッターはとても良いです',
    comment:
      'ベビーシッターはとても思いやりがあります',
  },
  {
    id: '3',
    source: Images.topsitter.sitter3,
    name: '美風友',
    rate: 5,
    date: 'May 2022',
    title: '素晴らしいベビーシッター',
    comment:
      'ベビーシッターはとても思いやりがあります',
  },
  {
    id: '4',
    source: Images.topsitter.sitter2,
    name: '紗莉奈',
    rate: 5,
    date: 'May 2022',
    title: 'ベビーシッターはとても良いです',
    comment:
      'ベビーシッターはとても思いやりがあります',
  },
];