export default {
  tabs: {
    home: "ホーム",
    messenger: "メッセンジャー",
    reserve: "予約",
    order: "訂單",
    calendar: "カレンダー",    
    profile: "私の"
  },
  search: {
    title: "搜尋",  
    search_nearby: "現在一番近くにペットシッター",
    cancel: "キャンセル",
    type_care_dog: "犬シッター",
    type_care_cat: "猫シッター",
    type_walking: "犬の散歩",
    type_care_baby_dog: "子犬シッター",
    type_care_baby_cat: "子猫シッター",
    type_pet_behavior: "トレーニング",
    type_care_old_dog: "老犬シッター",
    more: "もっと"
  },
  enum: {
    currency: "¥",
    top_sitter: "TOPペットシッター",
    area: "地域：東京都新宿区",
    lowest_price: "最低価格",
    hightest_price: "最高価格",
    hightest_rating: "最高の評価",
    popularity: "人気",
    apply: "応用",
    filter: "フィルター",
    reserve_now: "今予約する",
    rating: "コメント",
    feedback: "回答率",
    quick_reserve: "クイックオーダー",
    about_me: "私について",
    other_service: "その他のサービス",
    service_photo: "サービス写真",
    show_more: "続きを見る",
    service: "仕える",
    description: "詳細",
    reviews: "コメント",
    ratings: "コメント",
    continue: "継続する",    
    cancel: "キャンセル",
    done: "終了",
    setting: "設定",
    save: "保存",

    // calendar
    reserved: '予約済み',
    unreserve: '予約なし',    
    noreserve: "予約できません",
    full_reserve:  "予定がいっぱいです",
    
    // month
    month: "月",
    // types
    type_care_dog: "犬シッター",
    type_care_cat: "猫シッター",
    type_walking: "犬の散歩",
    type_care_baby_dog: "子犬シッター",
    type_care_baby_cat: "子猫シッター",
    type_pet_behavior: "トレーニング",
    type_care_old_dog: "老犬シッター",
    more: "もっと",
    // credit card
    credit_card: "クレジットカード",
    mobile_wallet: "モバイルウォレット",
    // Visit to the depositary
    visit_to_the_depositary: "最初の訪問"
  },
  license: {
    trimmer: "トリマー",
    domestic_dog_trainer: "家庭犬訓練士",
    animal_assisted_welfare_worker: "動物介在福祉士"
  },
  page: {
    sitter_detail: "ベビーシッターの詳細",
    preview_reserve: "予定の詳細",
    payment_method: "支払方法",
    reserve_detail: "予約"
  },
  preview_reserve: {
    pets: "ペット",
    start_date: "ご利用開始日",
    end_date: "ご利用終了日",
    times_per_day: "毎日回数",
    sub_total: "小計",
    dates_number: "日数",
    times_number: "次數",
    service_length: "服務時間",
    service_length_30mins: "サービス時間（一回30分）",
    address: "住所",
    notes: '備考',
  },
  reserve: {
    current_reserve: "現在の予約",
    history_reserve: "履歴予約",
    no_current_reserve: "予約はまだありません",
    no_history_reserve: "履歴の予約はまだありません"
  },
  // "about_us": "私たちに関しては",
  // "who_we_are": "私たちは誰ですか？",
  // "what_we_do": "私達がすること？",
  // "meet_our_team": "チームに会う",
  // "sologan_about_us": "過去への旅",
  // "our_service": "当社のサービス",
  // "change_language": "言語を変えてください",
  // "add_payment_method": "お支払い方法を追加",
  // "payment_method": "支払方法",
  // "payment": "支払い",
  // "payment_completed_text": "ありがとうございました。ご注文が完了しました。 SRR事務局からメールで連絡があり、登録の確認と会議の詳細の送信が行われます。",
  // "input_id": "入力ID",
  // "booking_id": "予約ID",
  // "credit_card_detail": "クレジットカードの詳細",
  // "domestic_card": "国内カード",
  // "credit_card": "クレジットカード",
  // "internet_banking": "ネットバンク",
  // "mobile_wallet": "モバイルウォレット",
  // "card_information": "カード情報",
  // "credit_card_number": "クレジットカード番号",
  // "valid_until": "まで有効",
  // "digit_num": "3桁のCCV",
  // "name_on_card": "カード上の名前",
  // "set_as_primary": "初期設定",
  // "pay_now": "今払う",
  // "delete": "削除する",
  // "done": "できた",
  // "add": "追加",
  // "shutle_bus_search": "シャトルバス検索",
  // "save": "保存する",
  // "passenger": "旅客",
  // "people": "人",
  // "hours": "時間",
  // "filtering": "フィルタリング",
  // "lowest_price": "最低価格",
  // "hightest_price": "最高価格",
  // "hightest_rating": "最高の評価",
  // "popularity": "人気",
  // "filter": "フィルタ",
  // "apply": "申し込む",
  // "from": "から",
  // "to": "に",
  // "price": "価格",
  // "avg_price": "AVG価格",
  // "results": "結果",
  // "shutle_bus": "シャトルバス",
  // "booking_history": "予約履歴",
  // "cancel": "キャンセル",
  // "preview": "プレビュー",
  // "confirm": "確認",
  // "complete": "コンプリート",
  // "detail": "細部",
  // "booking_detail": "予約の詳細",
  // "search_language": "言語を検索",
  // "search": "探す",
  // "home": "ホーム",
  // "booking": "予約",
  // "message": "メッセージ",
  // "news": "ニュース",
  // "account": "アカウント",
  // "select_seats": "座席を選択",
  // "booked": "予約済み",
  // "non_stop": "ノンストップ",
  // "per_day": "1日あたり",
  // "per_month": "月額",
  // "selected": "選択済み",
  // "empty": "空の",
  // "exit": "出口",
  // "tickets": "切符売場",
  // "total_price": "合計金額",
  // "book_now": "今予約する",
  // "time": "時間",
  // "persons": "人",
  // "seat": "シート",
  // "seat_number": "座席番号",
  // "ticket_no": "チケット番号",
  // "confirm_date": "確認日",
  // "total_fare": "総運賃",
  // "download": "ダウンロード",
  // "car": "車",
  // "economy": "経済",
  // "reviews": "レビュー",
  // "input": "入力レビュー",
  // "car_detail": "車の詳細",
  // "book": "本",
  // "information": "情報",
  // "features": "特徴",
  // "contact_host": "ホストに連絡",
  // "view_profile": "プロフィールを見る",
  // "profile": "プロフィール",
  // "activity": "アクティビティ",
  // "edit_profile": "プロファイル編集",
  // "change_password": "パスワードを変更する",
  // "password": "パスワード",
  // "input_password": "パスワードを入力",
  // "re_password": "再パスワード",
  // "password_confirm": "パスワード確認",
  // "check_out": "チェックアウト",
  // "reset": "リセット",
  // "billing_information": "課金情報",
  // "street_address": "住所",
  // "city": "市",
  // "post_code": "郵便番号",
  // "post": "役職",
  // "country": "国",
  // "contact_details": "連絡先の詳細",
  // "contact_name": "連絡先の名前",
  // "name": "名前",
  // "input_name": "入力名",
  // "email": "Eメール",
  // "input_email": "入力メール",
  // "code": "コード",
  // "input_address": "入力住所",
  // "phone_number": "電話番号",
  // "contact_us": "お問い合わせ",
  // "send": "送る",
  // "coupons": "クーポン",
  // "cruise": "クルーズ",
  // "itinerary": "旅程",
  // "feedback": "フィードバック",
  // "specials": "特別",
  // "avg_night": "AVG /夜",
  // "day": "日",
  // "ports": "ポート",
  // "arrive": "到着",
  // "depart": "出発",
  // "mon": "私の",
  // "tue": "あなた",
  // "wed": "結婚した",
  // "thur": "木",
  // "sun": "太陽",
  // "todo_things": "すべてのもの",
  // "show_more": "もっと見せる",
  // "if_you_have_question": "質問がある場合",
  // "tap_to_rate": "星をタップして評価",
  // "durating": "デュレーティング",
  // "rating": "評価",
  // "duration": "期間",
  // "days": "日々",
  // "night": "夜",
  // "cruise_search": "クルーズ検索",
  // "currency": "通貨",
  // "up_comming_events": "今後のイベント",
  // "recommend_for_you": "あなたにおすすめ",
  // "event": "イベント",
  // "date_time": "日付時刻",
  // "date": "日付",
  // "address": "住所",
  // "description": "説明文",
  // "see_details": "詳細を見る",
  // "facilities": "施設紹介",
  // "you_may_also_like": "あなたも好きかも",
  // "person_ticket": "人/チケット",
  // "category": "カテゴリー",
  // "preview_booking": "予約のプレビュー",
  // "adults": "大人",
  // "children": "こども",
  // "continue": "継続する",
  // "quess": "クエスト",
  // "infants": "乳幼児",
  // "room_type": "部屋タイプ",
  // "room": "ルーム",
  // "interio": "Interio",
  // "round_trip": "往復",
  // "one_way": "一方通行",
  // "avg_time": "AVG時間",
  // "transit": "トランジット",
  // "search_flight": "フライトを検索",
  // "flight": "フライト",
  // "gate": "ゲート",
  // "booking_summary": "予約の概要",
  // "all_charged_included": "すべて含まれています",
  // "class": "クラス",
  // "economic_class": "経済階級",
  // "what_are_you_looking_for": "何を探してるの？",
  // "promos_today": "今日のプロモーション",
  // "tours": "旅行",
  // "let_find_tour": "最も興味深いものを見つけましょう",
  // "comming_event": "今後のイベント",
  // "let_find_event": "起こりうる最悪の事態とは",
  // "promotion": "プロモーション",
  // "let_find_promotion": "起こりうる最悪の事態とは",
  // "hotels": "ホテル",
  // "excellent": "優秀な",
  // "hotel_description": "ホテルの説明",
  // "location": "ロケーション",
  // "good_to_know": "知っておきたい",
  // "hotel_information": "ホテル情報",
  // "facilities_of_hotel": "ホテル施設",
  // "messages": "メッセージ",
  // "type_message": "メッセージを入力...",
  // "messenger": "メッセンジャー",
  // "more": "もっと",
  // "my_cards": "私のカード",
  // "primary": "プライマリー",
  // "page_not_found": "ページが見つかりません",
  // "this_page_not_found": "このページは見つかりません",
  // "back": "バック",
  // "notification": "通知",
  // "join_now": "今すぐ参加",
  // "card_detail": "カード詳細",
  // "user_following": "ユーザーフォロー",
  // "top_experiences": "トップ体験",
  // "feature_post": "おすすめの投稿",
  // "price_table": "価格表",
  // "setting": "設定",
  // "sign_out": "サインアウト",
  // "follow": "従う",
  // "about_me": "私について",
  // "reminders": "リマインダー",
  // "work_progress": "作業進捗",
  // "reset_password": "パスワードを再設定する",
  // "email_address": "電子メールアドレス",
  // "years": "年",
  // "total_guest": "総ゲスト",
  // "search_history": "検索履歴",
  // "clear": "晴れ",
  // "discover_more": "もっと知る",
  // "refresh": "リフレッシュ",
  // "recently_view": "最近見た",
  // "search_bus": "シャトルバスを検索",
  // "search_cruise": "クルーズを検索",
  // "dynamic_system": "動的システム",
  // "always_on": "常にオン",
  // "always_off": "常にオフ",
  // "close": "閉じる",
  // "airplane": "飛行機",
  // "language": "言語",
  // "search_airplane": "飛行機を検索",
  // "theme": "テーマ",
  // "font": "書体",
  // "default": "デフォルト",
  // "dark_theme": "暗いテーマ",
  // "app_version": "アプリのバージョン",
  // "sign_in": "サインイン",
  // "forgot_your_password": "パスワードをお忘れですか？",
  // "sign_up": "サインアップ",
  // "packages": "パッケージ",
  // "travel_agency": "旅行会社",
  // "participants": "参加者",
  // "pick_your_destication": "旅行先を選ぶ",
  // "login_facebook": "Facebookでログイン",
  // "not_have_account": "まだ登録していませんか？",
  // "input_title": "タイトルを入力",
  // "all": "すべて",
  // "music": "音楽",
  // "sports": "スポーツ",
  // "shows": "ショー",
  // "discounts": "割引",
  // "ticket_reserved": "チケット予約席",
  // "ticket_vip": "チケットVIP",
  // "ticket_general": "チケット一般入場",
  // "sold_out": "完売",
  // "hour_ago": "時間前",
  // "views": "ビュー",
  // "people_like_this": "このような人々",
  // "total_ticket": "総チケット",
  // "show_qr_code_title": "入り口で、以下のQRコードを表示",
  // "thanks": "ありがとうございます",
  // "check_in_from": "チェックイン",
  // "check_out_from": "チェックアウト",
  // "replay": "リプレイ",
  // "basic_package": "基本パッケージ",
  // "standard_package": "標準パッケージ",
  // "premium_package": "プレミアムパッケージ",
  // "car_sologan": "スポーツ＆高級車",
  // "post_title": "秋の紅葉鑑賞旅行の準備はいいですか？",
  // "check_in": "チェックイン",
  // "bus_name": "バス名",
  // "depart_time": "出発時間",
  // "arrive_time": "到着時間",
  // "from_person": "から/人",
  // "ticket_sale": "チケット販売",
  // "bus": "バス"
}