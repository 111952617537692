import { en, ja, zh } from '../lang';

/**
 * Basic Setting Variables Define
 */
export const BaseSetting = {
  name: 'Fluv毛小愛',
  displayName: 'Fluv 毛小愛',
  appVersion: '1.0.0',
  defaultLanguage: 'ja',
  languageSupport: [
    'en',    
    'ja',
    'zh',
  ],
  resourcesLanguage: {
    'en': en,
    'ja': ja,
    'zh': zh
  },
};
