export default [    
  {
    icon: 'dog',
    name: 'type_care_dog',
    route: 'Search',
    type: 'care_dog'
  },
  {
    icon: 'cat',
    name: 'type_care_cat',
    route: 'Search',
    type: 'care_cat'  
  },
  {
    icon: 'dog',
    name: 'type_walking',
    route: 'Search',
    type: 'walking'
  },
  {
    icon: 'heart',
    name: 'type_care_baby_dog',
    route: 'Search',
    type: 'care_baby_dog'
  },
  {
    icon: 'heart',
    name: 'type_care_baby_cat',
    route: 'care',
    type: 'care_baby_cat'
  },
  {
    icon: 'bone',
    name: 'type_care_old_dog',
    route: 'Search',
    type: 'care_old_dog'  
  },
  {
    icon: 'heart',
    name: 'type_pet_behavior',
    route: 'Search',
    type: 'pet_behavior'
  },
  {
    icon: 'ellipsis-h',
    name: 'more',
    route: '',
    type: 'more'
  },
]