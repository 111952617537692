import React from 'react'
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { BaseColor, useTheme } from '../config';
import Icon from 'react-native-vector-icons/FontAwesome5';
import {useTranslation} from 'react-i18next';
import { 
  HomeScreen, 
  ReserveScreen, 
  MessengerScreen, 
  ProfileScreen 
} from '../screens';
import SearchScreen from '../screens/search/SearchScreen';
import CalendarScreen from '../screens/calendar/CalendarScreen';
import SitterDetail from '../screens/sitterDetail/SitterDetail';
import PreviewReserve from '../screens/previewReserve/PreviewReserve';
import PaymentMethod from '../screens/payment/PaymentMethod';


const MainStack =  createStackNavigator();
const BottomTab = createBottomTabNavigator();

const BottomTabNavigator = () => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <BottomTab.Navigator
      initialRouteName="Home"
      screenOptions={{
        tabBarInactiveTintColor: BaseColor.grayColor,
        tabBarActiveTintColor: colors.primary,
        headerShown: false,
        tabBarLabelStyle: {
          fontSize: 12,
          paddingBottom: 2,
        },
      }}
    >
      <BottomTab.Screen 
        name="Home" 
        component={HomeScreen}
        options={{
          headerShown: false,
          title: t('tabs:home'),
          tabBarIcon: ({color}) => {
            return <Icon name="home" color={color} size={20} solid/>;
          }
        }}
      />
      <BottomTab.Screen 
        name="Reserve"
        component={ReserveScreen}
        options={{
          title: t('tabs:reserve'),
          tabBarIcon: ({ color }) => {
            return <Icon name="bookmark" color={color} size={20} solid/>;
          }
        }}
      />
      <BottomTab.Screen 
        name="Messenger"
        component={MessengerScreen}
        options={{
          title: t('tabs:messenger'),
          tabBarIcon: ({ color }) => {
            return <Icon name='comment' color={color} size={20} solid/>
          }
        }}
      />
      <BottomTab.Screen 
        name="Calendar"
        component={CalendarScreen}
        options={{
          title: t('tabs:calendar'),
          tabBarIcon: ({ color }) => {
            return <Icon name='calendar' color={color} size={20} solid/>
          }
        }}
      />
      <BottomTab.Screen 
        name="profile"
        component={ProfileScreen}
        options={{
          title: t('tabs:profile'),
          tabBarIcon: ({ color }) => {
            return <Icon name='user' color={color} size={20} solid/>
          }
        }}
      />
    </BottomTab.Navigator>
  )
}

const Main = () => {
  return (
    <MainStack.Navigator
      screenOptions={{
        headerShown: false
      }}
    >
      <MainStack.Screen name="BottomTabNavigator" component={BottomTabNavigator}/>
      {/* Search */}
      <MainStack.Screen name="Search" component={SearchScreen} />
      <MainStack.Screen name="SitterDetail" component={SitterDetail} />
      <MainStack.Screen name="PreviewReserve" component={PreviewReserve} />
      <MainStack.Screen name="PaymentMethod" component={PaymentMethod} />
      {/* <MainStack.Screen name="Filter" component={FilterScreen} /> */}
    </MainStack.Navigator>
  )
}

export default Main