import { Image, ImageResizeMode, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import React from 'react'

interface ImageProps {
  style?: StyleProp<ViewStyle>,
  source: any,
  resizeMode?: ImageResizeMode
}

export default (props: ImageProps) => {
  const {style} = props;

  return (
    <View style={[styles.contaner, style]}>
      <Image {...props} style={[styles.content]}/>
    </View>
  )
}

const styles = StyleSheet.create({
  contaner: {
    overflow: 'hidden',
  },
  content: {width: '100%', height: '100%'},
});