import {Images} from '../config';

export default [
  {
    id: '1',
    image: Images.services.service1,
    name: '渡辺香織',
    location: '東京都新宿区',
    travelTime: '8 Days 7 Nights',
    startTime: 'July 19th 2022',
    price: '3500',
    rate: 5,
    rateCount: '85 of 100',
    numReviews: 100,
    author: {
      image: Images.services.service1,
      point: '9.5',
      name: 'by Steve Garrett',
    },
    tags: [
      {icon: 'certificate', name: 'domestic_dog_trainer'},
      {icon: 'id-card', name: 'animal_assisted_welfare_worker'},
      {icon: 'id-card', name: 'trimmer'},
    ],    
  },
  {
    id: '2',
    image: Images.services.service2,
    name: '佐藤麻里子',
    location: '東京都新宿区',
    travelTime: '8 Days 7 Nights',
    startTime: 'July 19th 2022',
    price: '3000',
    rate: 5,
    rateCount: '85 of 100',
    numReviews: 100,
    author: {
      image: Images.services.service2,
      point: '9.5',
      name: 'by Steve Garrett',
    },
    tags: [
      {icon: 'certificate', name: 'domestic_dog_trainer'},
      {icon: 'id-card', name: 'animal_assisted_welfare_worker'},
    ],
  },
  {
    id: '3',
    image: Images.services.service3,
    name: '西藤和恵',
    location: '東京都新宿区',
    travelTime: '8 Days 7 Nights',
    startTime: 'May 30th 2022',
    price: '4000',
    rate: 5,
    rateCount: '85 of 100',
    numReviews: 100,
    author: {
      image: Images.services.service3,
      point: '9.5',
      name: 'by Steve Garrett',
    },
    tags: [
      {icon: 'id-card', name: 'animal_assisted_welfare_worker'},
      {icon: 'id-card', name: 'trimmer'},
    ],
  },
  {
    id: '4',
    image: Images.services.service4,
    name: '野口有美子',
    location: '東京都新宿区',
    travelTime: '8 Days 7 Nights',
    startTime: 'July 19th 2022',
    price: '3000',
    rate: 5,
    rateCount: '85 of 100',
    numReviews: 100,
    author: {
      image: Images.services.service4,
      point: '9.5',
      name: 'by Steve Garrett',
    },
    tags: [
      {icon: 'certificate', name: 'domestic_dog_trainer'},
      {icon: 'id-card', name: 'trimmer'},
    ],
  },  
  {
    id: '5',
    image: Images.services.service5,
    name: 'Tour in Portugal',
    location: '東京都新宿区',
    travelTime: '8 Days 7 Nights',
    startTime: 'July 19th 2022',
    price: '3000',
    rate: 4,
    rateCount: '85 of 100',
    numReviews: 100,
    author: {
      image: Images.services.service5,
      point: '9.5',
      name: 'by Steve Garrett',
    },
    tags: [
      {icon: 'certificate', name: 'domestic_dog_trainer'},
      {icon: 'id-card', name: 'animal_assisted_welfare_worker'},
    ],
  }, 
  {
    id: '6',
    image: Images.services.service6,
    name: 'Tour in Portugal',
    location: '東京都新宿区',
    travelTime: '8 Days 7 Nights',
    startTime: 'July 19th 2022',
    price: '3000',
    rate: 4,
    rateCount: '85 of 100',
    numReviews: 100,
    author: {
      image: Images.services.service6,
      point: '9.5',
      name: 'by Steve Garrett',
    },
    tags: [
      {icon: 'certificate', name: 'domestic_dog_trainer'},
      {icon: 'id-card', name: 'animal_assisted_welfare_worker'},
    ],
  },  
  {
    id: '7',
    image: Images.services.service7,
    name: 'Tour in Portugal',
    location: '東京都新宿区',
    travelTime: '8 Days 7 Nights',
    startTime: 'July 19th 2022',
    price: '3000',
    rate: 4,
    rateCount: '85 of 100',
    numReviews: 100,
    author: {
      image: Images.services.service7,
      point: '9.5',
      name: 'by Steve Garrett',
    },
    tags: [
      {icon: 'certificate', name: 'domestic_dog_trainer'},
      {icon: 'id-card', name: 'animal_assisted_welfare_worker'},
      {icon: 'id-card', name: 'trimmer'},
    ], 
  },   
  {
    id: '8',
    image: Images.services.service8,
    name: 'Tour in Portugal',
    location: '東京都新宿区',
    travelTime: '8 Days 7 Nights',
    startTime: 'July 19th 2022',
    price: '3000',
    rate: 4,
    rateCount: '85 of 100',
    numReviews: 100,
    author: {
      image: Images.services.service8,
      point: '9.5',
      name: 'by Steve Garrett',
    },
    tags: [
      {icon: 'certificate', name: 'domestic_dog_trainer'},
      {icon: 'id-card', name: 'animal_assisted_welfare_worker'},
      {icon: 'id-card', name: 'trimmer'},
    ], 
  },  
  {
    id: '9',
    image: Images.services.service9,
    name: 'Tour in Portugal',
    location: '東京都新宿区',
    travelTime: '8 Days 7 Nights',
    startTime: 'July 19th 2022',
    price: '3000',
    rate: 4,
    rateCount: '85 of 100',
    numReviews: 100,
    author: {
      image: Images.services.service9,
      point: '9.5',
      name: 'by Steve Garrett',
    },
    tags: [
      {icon: 'certificate', name: 'domestic_dog_trainer'},
      {icon: 'id-card', name: 'animal_assisted_welfare_worker'},
      {icon: 'id-card', name: 'trimmer'},
    ], 
  },  
];
