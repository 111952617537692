import React from 'react';
import {TouchableOpacity, StyleSheet, ActivityIndicator, StyleProp, ViewStyle, PressableProps} from 'react-native';
import {BaseColor, useTheme, Typography} from '../../config';
import PropTypes from 'prop-types';
import {Text} from '../index';
interface ButtonProps {
  style?: StyleProp<ViewStyle>,
  styleText?: any,
  icon?: any,
  outline?: any,
  full?: any,
  round?: any,
  loading?: boolean,
  children?: any,
  onPress?: any
}
export default (props: ButtonProps) => {
  const {colors} = useTheme();
  const {
    style,
    styleText,
    icon,
    outline,
    full,
    round,
    loading,
    children,
    onPress  
  } = props;

  return (
    <TouchableOpacity
      onPress={onPress}
      style={StyleSheet.flatten([
        [styles.default, {backgroundColor: colors.primary}],
        outline && [
          styles.outline,
          {backgroundColor: colors.card, borderColor: colors.primary},
        ],
        full && styles.full,
        round && styles.round,
        style,
      ])}
      activeOpacity={0.9}>
      {icon ? icon : null}
      <Text
        style={StyleSheet.flatten([
          styles.textDefault,
          outline && {color: colors.primary},
          styleText,
        ])}
        numberOfLines={1}>
        {children || 'Button'}
      </Text>
      {loading ? (
        <ActivityIndicator
          size="small"
          color={outline ? colors.primary : BaseColor.whiteColor}
          style={{paddingLeft: 5}}
        />
      ) : null}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  default: {
    height: 56,
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  textDefault: {
    ...Typography.headline,
    color: BaseColor.whiteColor,
  },
  outline: {
    borderWidth: 1,
  },

  full: {
    width: '100%',
    alignSelf: 'auto',
  },
  round: {
    borderRadius: 28,
  },
});


