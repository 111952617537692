export default [
  {id: '1', number: '1', status: 'reserved'},
  {id: '2', number: '2', status: 'empty'},
  {id: '3', number: '3', status: 'empty'},
  {id: '4', number: '4', status: 'empty'},
  {id: '5', number: '5', status: 'empty'},
  {id: '6', number: '6', status: 'empty'},
  {id: '7', number: '7', status: 'empty'},
  {id: '8', number: '8', status: 'reserved'},
  {id: '9', number: '9', status: 'empty'},
  {id: '10', number: '10', status: 'empty'},
  {id: '11', number: '11', status: 'empty'},
  {id: '12', number: '12', status: 'full'},
  {id: '13', number: '13', status: 'empty'},
  {id: '14', number: '14', status: 'empty'},
  {id: '15', number: '15', status: 'full'},
  {id: '16', number: '16', status: 'empty'},
  {id: '17', number: '17', status: 'reserved'},
  {id: '18', number: '18', status: 'empty'},
  {id: '19', number: '19', status: 'empty'},
  {id: '20', number: '20', status: 'empty'},
  {id: '21', number: '21', status: 'reserved'},
  {id: '22', number: '22', status: 'empty'},
  {id: '23', number: '23', status: 'full'},
  {id: '24', number: '24', status: 'empty'},
  {id: '25', number: '25', status: 'empty'},
  {id: '26', number: '26', status: 'empty'},
  {id: '27', number: '27', status: 'empty'},
  {id: '28', number: '28', status: 'empty'},
  {id: '29', number: '29', status: 'empty'},
  {id: '30', number: '30', status: 'empty'},
  {id: '31', number: '31', status: 'empty'},
]