export default {
  tabs: {
    home: "首頁",
    messenger: "訊息",
    reserve: "預約",
    order: "訂單",
    calendar: "行事曆",
    profile: "我的"
  },
  search: {
    title: "搜尋",
    search_nearby: "目前離你最近的寵物保姆",
    cancel: "取消",    
    type_care_dog: "狗保姆",
    type_care_cat: "貓保姆",
    type_walking: "陪伴散步",
    type_care_baby_dog: "幼犬保姆",
    type_care_baby_cat: "幼貓保姆",
    type_pet_behavior: "行為訓練",
    type_care_old_dog: "老犬保姆",
    more: "了解更多"
  },
  enum: {
    currency: "NT",
    top_sitter: "TOP 寵物保姆",
    area: "地區：東京都新宿區",
    filtering: "篩選",
    lowest_price: "最低價格",
    hightest_price: "最高價格",
    hightest_rating: "最高評分",
    popularity: "人氣度",
    apply: "應用",
    filter: "篩選",
    reserve_now: "立即預約",
    rating: "評論",
    feedback: "回覆率",
    quick_reserve: "快速下訂",
    about_me: "關於我",
    other_service: "其他服務",
    service_photo: "服務照片",
    show_more: "查看更多",
    service: "服務",
    desceription: "詳細資訊",
    reviews: "評論",
    ratings: "則評論",
    continue: "繼續",
    cancel: "取消",
    done: "完成",
    setting: "設定",
    save: "保存",
    // calendar
    reserved: "有被預約",
    unreserve: "未預約",
    noreserve: "無法預約",
    full_reserve:  "預約已滿",
    // month
    month: "月",
    // types
    type_care_dog: "狗保姆",
    type_care_cat: "貓保姆",
    type_walking: "陪伴散步",
    type_care_baby_dog: "幼犬保姆",
    type_care_baby_cat: "幼貓保姆",
    type_pet_behavior: "行為訓練",
    type_care_old_dog: "老犬保姆",
    more: "了解更多",
    // credit card
    credit_card: "信用卡",
    mobile_wallet: "手機錢包",
    // Visit to the depositary
    visit_to_the_depositary: "預約家訪"
  },
  license: {
    trimmer: "修剪師",
    domestic_dog_trainer: "家養犬訓練師",
    animal_assisted_welfare_worker: "動物輔助福利工作者"
  },
  page: {
    sitter_detail: "保姆細節",
    preview_reserve: "預約細節",
    payment_method: "付款方式",
    reserve_detail: "預約"
  },
  preview_reserve: {
    pets: "寵物",
    start_date: "開始時間",
    end_date: "結束時間",
    times_per_day: "每天次數",
    sub_total: "小計",
    dates_number: "天數",
    times_number: "次數",
    service_length: "服務時間",
    service_length_30mins: "服務時間（每 30 分鐘）",
    address: "地址",
    notes: '備註',
  },
  reserve: {
    current_reserve: "當前預約",
    history_reserve: "歷史預約",
    no_current_reserve: "尚無預約",
    no_history_reserve: "尚無歷史預約",
  },

  // "about_us": "关于我们",
  // "who_we_are": "我们是谁？",
  // "what_we_do": "我们所做的？",
  // "meet_our_team": "认识我们的团队",
  // "sologan_about_us": "过去的旅程",
  // "our_service": "我们的服务",
  // "change_language": "改变语言",
  // "add_payment_method": "添加付款方式",
  // "payment_method": "付款方法",
  // "payment": "付款",
  // "payment_completed_text": "谢谢。您的订单已完成。 SRR秘书处将通过电子邮件与您联系，以确认您的注册并向您发送会议的详细信息。",
  // "input_id": "输入ID",
  // "booking_id": "预订编号",
  // "credit_card_detail": "信用卡细节",
  // "domestic_card": "国内卡",
  // "credit_card": "信用卡",
  // "internet_banking": "网上银行",
  // "mobile_wallet": "手机钱包",
  // "card_information": "卡信息",
  // "credit_card_number": "信用卡号",
  // "valid_until": "有效期至",
  // "digit_num": "3位CCV",
  // "name_on_card": "卡片上的名字",
  // "set_as_primary": "设为主要",
  // "pay_now": "现在付款",
  // "delete": "删除",
  // "done": "完成",
  // "add": "加",
  // "shutle_bus_search": "穿梭巴士搜索",
  // "save": "保存",
  // "passenger": "乘客",
  // "people": "人",
  // "hours": "小时",
  // "filtering": "筛选",
  // "lowest_price": "最低价格",
  // "hightest_price": "最高价",
  // "hightest_rating": "最高评分",
  // "popularity": "人气度",
  // "filter": "过滤",
  // "apply": "应用",
  // "from": "从",
  // "to": "至",
  // "price": "价钱",
  // "avg_price": "平均价格",
  // "results": "结果",
  // "shutle_bus": "穿梭巴士",
  // "booking_history": "预订记录",
  // "cancel": "取消",
  // "preview": "预习",
  // "confirm": "确认",
  // "complete": "完成",
  // "detail": "详情",
  // "booking_detail": "预订详情",
  // "search_language": "学习语言",
  // "search": "搜索",
  // "home": "家",
  // "booking": "订票",
  // "message": "信息",
  // "news": "新闻",
  // "account": "帐户",
  // "select_seats": "选择座位",
  // "booked": "已预订",
  // "non_stop": "不间断",
  // "per_day": "每天",
  // "per_month": "每月",
  // "selected": "已选",
  // "empty": "空的",
  // "exit": "出口",
  // "tickets": "门票",
  // "total_price": "总价",
  // "book_now": "现在预订",
  // "time": "时间",
  // "persons": "人数",
  // "seat": "座位",
  // "seat_number": "座位号",
  // "ticket_no": "票号",
  // "confirm_date": "确认日期",
  // "total_fare": "总票价",
  // "download": "下载",
  // "car": "汽车",
  // "economy": "经济",
  // "reviews": "评论",
  // "input": "输入评论",
  // "car_detail": "汽车细节",
  // "book": "书",
  // "information": "信息",
  // "features": "特征",
  // "contact_host": "联系房东",
  // "view_profile": "查看资料",
  // "profile": "轮廓",
  // "activity": "活动",
  // "edit_profile": "编辑个人资料",
  // "change_password": "更改密码",
  // "password": "密码",
  // "input_password": "输入密码",
  // "re_password": "重新输入密码",
  // "password_confirm": "确认密码",
  // "check_out": "查看",
  // "reset": "重启",
  // "billing_information": "账单信息",
  // "street_address": "街道地址",
  // "city": "市",
  // "post_code": "邮编",
  // "post": "发布",
  // "country": "国家",
  // "contact_details": "联系方式",
  // "contact_name": "联系人姓名",
  // "name": "名称",
  // "input_name": "输入名称",
  // "email": "电子邮件",
  // "input_email": "输入电子邮件",
  // "code": "码",
  // "input_address": "输入地址",
  // "phone_number": "电话号码",
  // "contact_us": "联系我们",
  // "send": "发送",
  // "coupons": "优惠券",
  // "cruise": "巡航",
  // "itinerary": "行程",
  // "feedback": "反馈",
  // "specials": "特价商品",
  // "avg_night": "AVG /夜",
  // "day": "天",
  // "ports": "港口",
  // "arrive": "到达",
  // "depart": "离开",
  // "mon": "我的",
  // "tue": "您的",
  // "wed": "星期三",
  // "thur": "周四",
  // "sun": "太阳",
  // "todo_things": "万物",
  // "show_more": "显示更多",
  // "if_you_have_question": "如果你有任何问题",
  // "tap_to_rate": "点按星星即可评分",
  // "durating": "持续时间",
  // "rating": "评分",
  // "duration": "持续时间",
  // "days": "天",
  // "night": "晚",
  // "cruise_search": "邮轮搜索",
  // "currency": "货币",
  // "up_comming_events": "活动预告",
  // "recommend_for_you": "为你推荐",
  // "event": "事件",
  // "date_time": "约会时间",
  // "date": "日期",
  // "address": "地址",
  // "description": "描述",
  // "see_details": "查看详细信息",
  // "facilities": "设备",
  // "you_may_also_like": "你也许也喜欢",
  // "person_ticket": "人/票",
  // "category": "类别",
  // "preview_booking": "预览预订",
  // "adults": "大人",
  // "children": "童装",
  // "continue": "继续",
  // "quess": "质疑",
  // "infants": "婴幼儿",
  // "room_type": "房型",
  // "room": "房间",
  // "interio": "Interio",
  // "round_trip": "往返",
  // "one_way": "单程",
  // "avg_time": "平均时间",
  // "transit": "过境",
  // "search_flight": "搜索航班",
  // "flight": "飞行",
  // "gate": "门",
  // "booking_summary": "预订摘要",
  // "all_charged_included": "全部收费",
  // "class": "类",
  // "economic_class": "经济舱",
  // "what_are_you_looking_for": "你在找什么",
  // "promos_today": "今日促销",
  // "tours": "游览",
  // "let_find_tour": "让我们找出最有趣的事情",
  // "comming_event": "活动预告",
  // "let_find_event": "可能发生的最糟糕的事情是什么",
  // "promotion": "晋升",
  // "let_find_promotion": "可能发生的最糟糕的事情是什么",
  // "hotels": "旅馆",
  // "excellent": "优秀的",
  // "hotel_description": "酒店描述",
  // "location": "位置",
  // "good_to_know": "很高兴知道",
  // "hotel_information": "酒店信息",
  // "facilities_of_hotel": "酒店设施",
  // "messages": "留言内容",
  // "type_message": "输入讯息...",
  // "messenger": "信使",
  // "more": "更多",
  // "my_cards": "我的卡",
  // "primary": "主",
  // "page_not_found": "网页未找到",
  // "this_page_not_found": "找不到此页面",
  // "back": "背部",
  // "notification": "通知",
  // "join_now": "现在加入",
  // "card_detail": "卡明细",
  // "user_following": "用户关注",
  // "top_experiences": "最佳体验",
  // "feature_post": "精选文章",
  // "price_table": "定价表",
  // "setting": "设置",
  // "sign_out": "登出",
  // "follow": "跟随",
  // "about_me": "关于我",
  // "reminders": "提醒事项",
  // "work_progress": "工作进度",
  // "reset_password": "重设密码",
  // "email_address": "电子邮件地址",
  // "years": "年份",
  // "total_guest": "总客人",
  // "search_history": "搜索历史记录",
  // "clear": "明确",
  // "discover_more": "发现更多",
  // "refresh": "刷新",
  // "recently_view": "最近浏览过的",
  // "search_bus": "搜索穿梭巴士",
  // "search_cruise": "搜索游轮",
  // "dynamic_system": "动态系统",
  // "always_on": "永远在线",
  // "always_off": "总是关闭",
  // "close": "关",
  // "airplane": "飞机",
  // "language": "语言",
  // "search_airplane": "搜索飞机",
  // "theme": "主题",
  // "font": "字形",
  // "default": "默认",
  // "dark_theme": "黑暗主题",
  // "app_version": "应用程式版本",
  // "sign_in": "登入",
  // "forgot_your_password": "忘记密码了吗？",
  // "sign_up": "注册",
  // "packages": "配套",
  // "travel_agency": "旅行社",
  // "participants": "参加者",
  // "pick_your_destication": "选择您的旅行目的地",
  // "login_facebook": "用Facebook登录",
  // "not_have_account": "还没注册吗？",
  // "input_title": "输入标题",
  // "all": "所有",
  // "music": "音乐",
  // "sports": "体育",
  // "shows": "演出",
  // "discounts": "打折",
  // "ticket_reserved": "订票座位",
  // "ticket_vip": "门票贵宾",
  // "ticket_general": "门票一般入场",
  // "sold_out": "卖光了",
  // "hour_ago": "小时前",
  // "views": "观看次数",
  // "people_like_this": "像这样的人",
  // "total_ticket": "总票",
  // "show_qr_code_title": "在入口处，在下方显示QR码",
  // "thanks": "谢谢",
  // "check_in_from": "从签入",
  // "check_out_from": "签出",
  // "replay": "重播",
  // "basic_package": "基本套餐",
  // "standard_package": "标准包装",
  // "premium_package": "高级套餐",
  // "car_sologan": "跑车和豪华车",
  // "post_title": "准备好快去观赏秋天的树叶了吗？",
  // "check_in": "报到",
  // "bus_name": "巴士名称",
  // "depart_time": "出发时间",
  // "arrive_time": "到达时间",
  // "from_person": "来自/人",
  // "ticket_sale": "门票销售",
  // "bus": "总线"
}