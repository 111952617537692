import React, {useState} from 'react';
import {View, FlatList, TouchableOpacity, StyleSheet} from 'react-native';
import {BaseStyle, BaseColor, useTheme} from '../../config';
import {Header, SafeAreaView, Text, Button, Icon} from '../../components';
import {useTranslation} from 'react-i18next';
import { CalendarMonthBooking } from '../../fakedata';

export default function CalendarScreen({navigation}: any) {
  const {colors} = useTheme();
  const {t} = useTranslation();

  const [reserved, setReserved] = useState(CalendarMonthBooking);

  /**
   * Called when select seat
   * @param {*} selected
   */
  const selectNoReserve = (selected:any) => {
    switch (selected.status) {
      case 'noreserve':
        setReserved(
          reserved.map(item => {
            return {
              ...item,
              status: item.number == selected.number ? 'empty' : item.status,
            };
          }),
        );
        break;
      case 'reserved':
        break;
      default:
        setReserved(
          reserved.map(item => {
            return {
              ...item,
              status:
                item.number === selected.number ? 'noreserve' : item.status,
            };
          }),
        );
        
        break;
    }
  };


  const renderItem = (item:any, index:any) => {
    let styleReserve = {};
    switch (item.status) {
      case 'noreserve':
        styleReserve = {backgroundColor: BaseColor.dangerColor};
        break;
      case 'reserved':
        styleReserve = {backgroundColor: colors.primaryDark};
        break;
      default:
        styleReserve = {};
        break;
    }
    return (
      <View style={styles.itemDate}>
        <TouchableOpacity
          style={[styles.empty, styleReserve]}
          onPress={() => selectNoReserve(item)}>
          <Text title3 whiteColor semibold>
            {item.number}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  const ticket = reserved.filter(item => item.status === 'noreserve').length;
  return (
    <View style={{flex: 1}}>
      <Header
        title={t('tabs:calendar')}
        subTitle={''}
        renderRight={() => {
          return (
            <View>
              <Text headline primaryColor numberOfLines={1}>{t('enum:save')}</Text>
            </View>
          )
        }}
      />
      <SafeAreaView
        style={BaseStyle.safeAreaView}
        edges={['right', 'left', 'bottom']}>
        <View style={{paddingHorizontal: 20, flex: 1}}>
          {/* Status */}
          <View style={[styles.lineSeatType, {borderColor: colors.border}]}>

            <View style={{alignItems: 'center'}}>
              <View
                style={[styles.reserved, {backgroundColor: colors.primaryDark}]}
              />
              <Text footnote semibold>
                {t('enum:reserved')}
              </Text>
            </View>      

            <View style={{alignItems: 'center'}}>
              <View
                style={[styles.reserved, {backgroundColor: BaseColor.dangerColor}]}
              />
              <Text footnote semibold>
                {t('enum:noreserve')}
              </Text>
            </View>   

            <View style={{alignItems: 'center'}}>
              <View style={styles.empty} />
              <Text footnote semibold>
                {t('enum:unreserve')}
              </Text>
            </View>

          </View>
          {/* Icons */}
          <View style={styles.lineDirection}>
            <View>
              <Icon
                name="arrow-left"
                color={BaseColor.grayColor}
                size={18}
                solid
              />
            </View>

            <View>
              <Text style={{fontSize: 18, textAlign: 'center'}}>5{t('enum:month')}</Text>
            </View>

            <View>
              <Icon
                name="arrow-right"
                color={BaseColor.grayColor}
                size={18}
                solid
              />
            </View>
          </View>
          <View style={[styles.lineDirection, {justifyContent: 'center', alignItems: 'center'}]}>

          </View>
          {/* Seat render for select */}
          <FlatList
            numColumns={5}
            data={reserved}
            keyExtractor={(item, index) => item.id}
            renderItem={({item, index}) => renderItem(item, index)}
          />
        </View>       
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  contentButtonBottom: {
    borderTopWidth: 1,
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  lineSeatType: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 20,
    borderBottomWidth: 1,
  },
  reserved: {
    width: 30,
    height: 30,
    borderRadius: 8,
    marginBottom: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selected: {
    width: 30,
    height: 30,
    borderRadius: 8,
    marginBottom: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  empty: {
    width: 30,
    height: 30,
    borderRadius: 8,
    marginBottom: 5,
    backgroundColor: BaseColor.grayColor,
    alignItems: 'center',
    justifyContent: 'center',
  },
  lineDirection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 30,
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  itemDate: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 15,
  },
});

