import React from 'react';
import {View, TouchableOpacity, StyleSheet} from 'react-native';
import {BaseColor, BaseStyle, useTheme} from '../../config';
import {Header, SafeAreaView, Icon, Text} from '../../components';
import {useTranslation} from 'react-i18next';

export default function PaymentMethod({navigation}: any) {
  const {colors} = useTheme();
  const {t} = useTranslation();

  const onPreviewMethod = () => {
    navigation.navigate('PreviewPayment');
  };

  return (
    <View style={{flex: 1}}>
      <Header
        title={t('page:payment_method')}
        subTitle={''}
        renderLeft={() => {
          return (
            <Icon
              name="arrow-left"
              size={20}
              color={colors.primary}
            />
          );
        }}
        onPressLeft={() => {
          navigation.goBack();
        }}
      />
      <SafeAreaView
        style={BaseStyle.safeAreaView}
        edges={['right', 'left', 'bottom']}>
        <View style={styles.contain}>          
          <TouchableOpacity
            style={[
              styles.methodItem,
              {borderBottomColor: colors.border, borderBottomWidth: 1},
            ]}
            onPress={() => onPreviewMethod()}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <View style={styles.iconContent}>
                <Icon name="cc-visa" size={24} color={colors.text} />
              </View>
              <Text headline>{t('enum:credit_card')}</Text>
            </View>
            <Icon
              name="angle-right"
              size={18}
              color={colors.primary}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.methodItem}
            onPress={() => onPreviewMethod()}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <View style={styles.iconContent}>
                <Icon name="mobile-alt" size={24} color={colors.text} />
              </View>
              <Text headline>{t('enum:mobile_wallet')}</Text>
            </View>
            <Icon
              name="angle-right"
              size={18}
              color={colors.primary}
            />
          </TouchableOpacity>
        </View>
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  contain: {
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
  methodItem: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 15,
    marginBottom: 5,
  },
  iconContent: {
    width: 30,
    marginRight: 10,
    alignItems: 'center',
  },
  textInput: {
    
    height: 46,
    backgroundColor: BaseColor.fieldColor,
    borderRadius: 5,
    marginTop: 10,
    padding: 10,
    width: '100%',
  },
});