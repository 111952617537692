export const Images = {
  homeBanner: require('../../assets/images/home_banner.jpg'),
  // fakeData
  topsitter: {
    sitter1: require('../../assets/images/sitter4.png'),
    sitter2: require('../../assets/images/sitter2.png'),
    sitter3: require('../../assets/images/sitter3.jpg'),
    sitter4: require('../../assets/images/sitter1.jpg'),
  },
  services: {
    // service
    service1: require('../../assets/images/service1.jpg'),
    service2: require('../../assets/images/service2.jpg'),
    service3: require('../../assets/images/service3.jpg'),
    service4: require('../../assets/images/service4.jpg'),
    service5: require('../../assets/images/service5.jpg'),
    service6: require('../../assets/images/service6.jpg'),
    service7: require('../../assets/images/service7.jpg'),
    service8: require('../../assets/images/service8.jpg'),
    service9: require('../../assets/images/service9.jpg'),
    service10: require('../../assets/images/service10.jpg'),
    service11: require('../../assets/images/service11.jpg'),    
  },
  banners: {
    banner1: require('../../assets/images/banner1.png'),
    banner2: require('../../assets/images/banner2.png'),
    banner3: require('../../assets/images/banner3.png'),
    banner4: require('../../assets/images/banner4.png'),
  }
}