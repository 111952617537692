import React from 'react';
import {View, StyleSheet} from 'react-native';
import {Images, BaseColor, useTheme} from '../config';
import {Text, Image, StarRating} from '../components';
import PropTypes from 'prop-types';

export default function CommentItem(props: any) {
  const {colors} = useTheme();
  const cardColor = colors.card;
  const {style, image, name, rate, date, title, comment} = props;
  return (
    <View style={[styles.contain, {backgroundColor: cardColor}, style]}>
      <View style={{flexDirection: 'row', marginBottom: 10}}>
        <View style={styles.contentLeft}>
          <Image source={image} style={styles.thumb} />
          <View>
            <Text headline semibold numberOfLines={1}>
              {name}
            </Text>
            <View style={styles.contentRate}>
              <StarRating
                disabled={true}
                starSize={14}
                maxStars={5}
                rating={rate}
                selectedStar={rating => {}}
                fullStarColor={BaseColor.yellowColor}
              />
            </View>
          </View>
        </View>
        <View style={styles.contentRight}>
          <Text caption2 grayColor numberOfLines={1}>
            {date}
          </Text>
        </View>
      </View>
      <View>
        <Text subhead semibold>
          {title}
        </Text>
        <Text
          body2
          grayColor
          style={{
            marginTop: 10,
          }}>
          {comment}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  contain: {
    borderRadius: 8,
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  contentLeft: {
    flex: 8,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  thumb: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 5,
  },
  contentRight: {
    flex: 2,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  contentRate: {
    flex: 1,
    marginTop: 5,
    flexDirection: 'row',
  },
});