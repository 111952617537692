import { StyleSheet, View, TouchableOpacity, StyleProp, ViewStyle } from 'react-native';
import React, { ReactNode } from 'react';
import {Image} from '../index';
import { useTheme } from '../../config';
interface CardProps {
  style?: StyleProp<ViewStyle>,
  children?: ReactNode, 
  styleContent?: any, 
  image?: any,
  onPress?: any
}
export default (props: CardProps) => {
  const {colors} = useTheme();
  const {style, children, styleContent, image, onPress} = props;

  return (
    <TouchableOpacity
      style={[styles.card, {borderColor: colors.border}, style]}
      onPress={onPress}
      activeOpacity={0.9}>
      <Image source={image} style={styles.imageBanner}/>
      <View style={[styles.content, styleContent]}>{children}</View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  imageBanner: {flex: 1, borderRadius: 8},
  card: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
  },
  content: {
    position: 'absolute',
    alignItems: 'flex-start',
    bottom: 0,
    padding: 10,
  },
});
